var render = function () {
  var _vm$segmentList;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-cancel-flight-vn1a",
      "title": _vm.$t('reservation.cancelFlight'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-75",
      "footer-class": "px-50 m-0",
      "centered": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.showHandle
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [(_vm$segmentList = _vm.segmentList) !== null && _vm$segmentList !== void 0 && _vm$segmentList.length ? _c('div', [_c('div', {
    staticClass: "text-medium-4 d-flex-between font-weight-bolder"
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('reservation.selectItineraryToCancel')))]), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "rounded-circle p-50",
    attrs: {
      "variant": "flat-dark",
      "title": "Tải lại hành trình"
    },
    on: {
      "click": _vm.getSegment
    }
  }, [_c('feather-icon', {
    class: "".concat(_vm.loading ? 'spinner-1s' : ''),
    attrs: {
      "icon": "RotateCwIcon",
      "size": "16"
    }
  })], 1)], 1), _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('b-form-checkbox', {
          staticClass: "custom-checkbox-label mb-50",
          attrs: {
            "aria-describedby": "flights",
            "aria-controls": "flights"
          },
          on: {
            "change": _vm.toggleAll
          },
          model: {
            value: _vm.allSelected,
            callback: function callback($$v) {
              _vm.allSelected = $$v;
            },
            expression: "allSelected"
          }
        }, [_c('div', {
          staticClass: "text-body-2"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.selectAll')) + " ")])])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          attrs: {
            "id": "checkbox-group-cancel-flight-vn1a",
            "aria-describedby": ariaDescribedby,
            "stacked": "",
            "name": "flights"
          },
          model: {
            value: _vm.selectedFlightToCancel,
            callback: function callback($$v) {
              _vm.selectedFlightToCancel = $$v;
            },
            expression: "selectedFlightToCancel"
          }
        }, _vm._l(_vm.segmentList, function (segment) {
          return _c('div', {
            key: segment.index,
            staticClass: "d-flex "
          }, [_c('b-form-checkbox', {
            staticClass: "custom-checkbox-label mb-50",
            attrs: {
              "value": segment
            }
          }, [_c('span', {
            staticClass: "font-weight-bolder font-medium-3 text-dark"
          }, [_vm._v(_vm._s(segment.segmentText))])])], 1);
        }), 0)];
      }
    }], null, false, 484203314)
  }), _c('BAlert', {
    staticClass: "px-2 py-1 mt-50",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Hủy chuyến bay sẽ hủy hẹn giờ xuất vé (nếu có) ")])], 1) : _vm.responseText ? [_c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif",
      "height": "70vh",
      "overflow": "auto"
    },
    attrs: {
      "value": _vm.responseText.booking.join('\n'),
      "rows": "16",
      "max-rows": "40"
    }
  })] : _c('div', {
    staticClass: "d-flex-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('loading')) + " ")]), _c('div', {
    staticClass: "d-flex-center gap-2 my-50"
  }, [_c('b-button', {
    staticClass: "center rounded-pill px-1",
    attrs: {
      "variant": "outline-secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.closeHandle();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), !_vm.responseText ? _c('b-button', {
    staticClass: "border-0 px-1",
    attrs: {
      "variant": "outline-danger",
      "disabled": !_vm.selectedFlightToCancelLength,
      "pill": ""
    },
    on: {
      "click": _vm.submitHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.cancelFlight')) + " ")]) : _vm._e()], 1)], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }